.TeamBiosSection2 {
  &__person {
    padding: 1rem 0;
  }

  &__avatar-wrapper {
    display: inline-block;
  }

  &__details {
    margin-top: 20px;
  }
}
